const config = {
  "apigateway_key": "YkR4blBGS1B4ajFVd0RpUWlXT2lkc3dEMQ==",
  "version": "1.0.202211180840",
  "application_name": "Seacom Kenya",
  "serverUrl": "https://seacomkenya.gms.telcotech.co",
  "menuExclusionsList": [
    "/authentication/login"
    , "/authentication/reset-password"
  ],
  "authentication": {
    "defaultUserRegistrationGroup": "1"
  },
  "refresh_interval": 30000
}

export { config }
//http://13.245.156.220:8082
//"serverUrl": "http://13.245.156.220:8094",